.ticket {
    position: relative;
}

.pendingTicket {
    cursor: unset;
}

.noTicketsDiv {
    display: flex;
    height: 100px;
    margin: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.noTicketsText {
    text-align: center;
    color: rgb(104, 121, 146);
    font-size: 14px;
    line-height: 1.4;
}

.noTicketsTitle {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

.closedBadge {
    margin-bottom: 5px;
    margin-left: 7px;
}

.closedLabel {
    padding: 5px;
}

.contactLastMessageClosed {
    padding-right: 20px !important;
    margin-left: 25px !important;
}

.ticketQueueColor {
    flex: none;
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
}

.userTag {
    position: absolute;
    margin-right: 5px;
    right: 5px;
    bottom: 5px;
    background: #2576D2;
    color: #ffffff;
    border: 1px solid #CCC;
    padding: 1px 5px;
    border-radius: 10px;
    font-size: 0.9em;
}

.gridWithCustomSpacing {
    width: calc(100% + 32px) !important;
    margin: -30px !important;
}

.badgeStyle {
    color: white;
    background-color: #4caf50;
    margin-right: -68px;
}

.acceptButton {
    position: absolute;
    left: 74%;
    padding: 0px;
    bottom: 9px;
    text-transform: none;
    font-weight: 500;
}

.lastMessageTime {
    justify-self: flex-end !important;
    margin-left: 47px !important;
    letter-spacing: -1px !important;
}

.lastMessageDate {
    margin-left: 15px !important;
    letter-spacing: -1px !important;
}

.reopenButton {
    padding: 0 !important;
    bottom: 7px !important;
    right: 8px !important;
    text-transform: none !important;
    font-weight: 500 !important;
}
